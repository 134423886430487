import React from "react"
import { css } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"
import { color, fontStyle, spacing } from "../defaults/styles/theme"
import { wrapper, IconButton } from "../defaults/styles/elements"
import Video from "../defaults/assets/svg/video.svg"

const landingButton = css`
  padding: 1em 2em;
  font-size: 1em;
  font-weight: 600;
  margin: 0 1em 1em 0;
  &:before {
    border-radius: 10px;
  }
`

const FormerSpeaker = () => {
  return (
    <section
      css={[
        spacing.paddingSection,
        css`
          background: ${color.richBlack};
        `,
      ]}
    >
      <div
        css={[
          wrapper,
          css`
            margin-bottom: 1em;
          `,
        ]}
      >
        <h2 css={fontStyle.header.landingSection}>
          A glance into{" "}
          <span
            css={css`
              background-image: linear-gradient(
                135deg,
                ${color.plue400} 0%,
                ${color.plue100} 100%
              );
              background-clip: text;
              text-fill-color: transparent;
              background-repeat: no-repeat;
            `}
          >
            the past
          </span>
        </h2>
        <div
          css={css`
            p {
              ${fontStyle.copy.landing}
            }
          `}
        >
          <p>
            We are thrilled to host our amazing speakers this year. Check out
            what‘s coming up or take a look at our last in-person EXPO recap.
          </p>
        </div>
        <IconButton
          name="Speakers of EXPO2022"
          to="/people/all"
          css={[
            landingButton,
            css`
              display: inline-flex;
              align-items: center;
              &:before {
                background: none;
                border: 1px solid ${color.gray400};
              }
            `,
          ]}
        />
        <IconButton
          name="EXPO Day 7 Recap"
          to="https://www.youtube.com/watch?v=XvF037oCHKQ&t=57s"
          icon={<Video />}
          css={[
            landingButton,
            css`
              display: inline-flex;
              align-items: center;
              &:before {
                background: none;
                border: 1px solid ${color.gray400};
              }
            `,
          ]}
        />
      </div>
      <div
        css={css`
          display: flex;
          justify-content: center;
          overflow: hidden;
        `}
      >
        <StaticImage
          src="../../../static/img/20220524-former_speaker.png"
          alt="Former EXPO speaker of STARTUP AUTOBAHN powered by Plug and Play"
          quality="90"
          placeholder="tracedSVG"
          css={css`
            min-width: 1600px;
          `}
        />
      </div>
    </section>
  )
}

export default FormerSpeaker
