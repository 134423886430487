import React, { useEffect, useState } from "react"
import "@fontsource/inter/latin.css"
import Layout from "../components/layout"
import VisualHeader from "../components/landing/visual_header"
import MoreToCome from "../components/landing/more_to_come"
import PartnerMarquee from "../components/landing/partner_marquee"
import FormerSpeaker from "../components/landing/former_speaker"
import Expectations from "../components/landing/expectations"
import GeneralFooter from "../components/general_footer"
import Join from "../components/landing/join"
import Booklet from "../components/landing/booklet"
import { color } from "../components/defaults/styles/theme"

const Open = () => {
  const [height, setHeight] = useState(null)

  useEffect(() => {
    setHeight(window.innerHeight)
  }, [])

  return (
    <Layout dark darkFooter footerBackground={color.richBlack}>
      <VisualHeader windowHeight={height} />
      <FormerSpeaker />
      <Expectations />
      <Join />
      <Booklet />
      <MoreToCome windowHeight={height} />
      <PartnerMarquee />
      <GeneralFooter theme={color.richBlack} darkFooter />
    </Layout>
  )
}

export default Open
