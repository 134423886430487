import React from "react"
import { css } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"
import { fontStyle, color, spacing, size } from "../defaults/styles/theme"
import { wrapper } from "../defaults/styles/elements"
import mq from "../defaults/styles/mediaquerys"
import { IconButton } from "../defaults/styles/elements"

const Booklet = () => {
  return (
    <section
      css={[
        spacing.paddingSection,
        css`
          background: ${color.richBlack};
          overflow-x: clip;
          position: relative;
        `,
      ]}
    >
      <div
        css={[
          wrapper,
          css`
            overflow-x: visible;
            display: flex;
            flex-direction: column;
            > * {
            }
          `,
        ]}
      >
        <div
          css={css`
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: flex-start;
            ${mq[1]} {
              align-items: flex-start;
              text-align: left;
              margin-right: 40%;
              min-height: 529px;
            }
            ${mq[2]} {
              margin-right: 50%;
            }
          `}
        >
          <h2 css={fontStyle.header.landingSection}>
            The{" "}
            <span
              css={css`
                background-image: linear-gradient(
                  135deg,
                  ${color.plue400} 0%,
                  ${color.plue100} 100%
                );
                background-clip: text;
                text-fill-color: transparent;
                background-repeat: no-repeat;
              `}
            >
              EXPO Booklet
            </span>{" "}
            has been revived
          </h2>
          <div
            css={css`
              display: block;
              max-height: 20rem;
              overflow-y: clip;
              margin-left: 10%;
              margin-bottom: 1em;
              ${mq[1]} {
                display: none;
              }
            `}
          >
            <StaticImage
              src="../../../static/img/expo2022-magazine1web.png"
              alt="Mockup of the official EXPO2022 magazine"
              quality="100"
              placeholder="tracedSVG"
              css={css`
                min-width: 110%;
                ${mq[1]} {
                  min-width: 700px;
                  max-width: 700px;
                }
              `}
            />
          </div>
          <div
            css={[
              css`
                p {
                  ${fontStyle.copy.landing}
                }
              `,
            ]}
          >
            {/* <p>
              While we are back in person this time and finally able to host more
              than a thousand open-innovation enthusiasts right in Stuttgart — we
              surely don‘t forget about our global community.
            </p> */}
            <p>
              A lot of things have been shifted to fully digital in recent
              months — something that also happened to our EXPO booklet which
              has been kept in a stealth mode for quite a while now.
            </p>
            <p>
              This year however we decided to revive it and — since not
              everybody will be able to make it to the location on July 7th — we
              also provide you with a digital copy. Please avoid printing the
              pdf ;)
            </p>
            {/* <p
              css={css`
                line-height: 1em;
              `}
            >
              <span
                css={css`
                  font-size: 0.8rem;
                  opacity: 0.4;
                  max-width: 25rem;
                  display: inline-block;
                `}
              >
                The booklet will be available shortly before the event. Stay
                tuned for more information.
              </span>
            </p> */}
          </div>
          <IconButton
            url="/media/startupautobahn-expo2022-booklet.pdf"
            css={css`
              padding: 1em 2em;
              font-size: 1em;
              font-weight: 600;
              margin-bottom: 1em;
              opacity: 0.3;
              &[href] {
                opacity: 1;
              }
              &:before {
                border-radius: 10px;
                background: none;
                border: 1px solid ${color.gray400};
              }
            `}
            name="Get your copy now"
          />
        </div>
        <div
          css={css`
            display: none;
            ${mq[1]} {
              position: relative;
              display: flex;
              justify-content: flex-end;
              margin-top: unset;
              max-height: unset;
              align-items: unset;
              overflow: hidden;
              left: calc(60% + 4em);
              position: absolute;
            }
            ${mq[2]} {
              left: calc(50% + 4em);
            }
          `}
        >
          <StaticImage
            src="../../../static/img/expo2022-magazine1web.png"
            alt="Mockup of the official EXPO2022 magazine"
            quality="100"
            placeholder="tracedSVG"
            css={css`
              min-width: 110%;
              ${mq[1]} {
                min-width: 700px;
                max-width: 700px;
              }
            `}
          />
        </div>
      </div>
    </section>
  )
}

export default Booklet
