import React from "react"
import { css } from "@emotion/react"
import { wrapper } from "../defaults/styles/elements"
import { color } from "../defaults/styles/theme"
import mq from "../defaults/styles/mediaquerys"
import Signature from "../defaults/assets/svg/signature.svg"
import Flags from "../../data/raw/flags"

const MoreToCome = ({ windowHeight }) => {
  return (
    <section
      css={css`
        background: ${color.richBlack};
        min-height: ${windowHeight ? windowHeight + "px" : "100vh"};
        padding: 10em 0;
        display: flex;
        align-items: center;
      `}
    >
      <div
        css={[
          wrapper,
          css`
            color: white;
            font-weight: 500;
            font-size: 1.15em;
            text-align: center;
            letter-spacing: 0.01em;
            display: flex;
            align-items: center;
            flex-direction: column;
            & > p:last-of-type {
              margin-top: 0.75em;
              margin-bottom: 0.5em;
              font-size: 0.9rem;
            }
            ${mq[2]} {
              font-size: 1.5em;
              & > p:last-of-type {
                font-size: 1rem;
              }
            }
          `,
        ]}
      >
        <p>
          {Flags.features.live
            ? "Enjoy the show."
            : "Stay tuned for more information."}
          <br />{" "}
          {Flags.features.rearview
            ? "This website will still be consistently updated with rearview videos."
            : "This website will be consistently updated with further details."}
        </p>
        <p>#StaySafe and innovate on.</p>
        <p>See you soon</p>

        <div>
          <p
            css={css`
              margin-top: 2em;
              margin-bottom: 0;
            `}
          >
            Plug and Play
          </p>
          <p
            css={css`
              font-size: 0.9rem;
              margin-bottom: 0;
              ${mq[2]} {
                font-size: 1rem;
              }
            `}
          >
            and
          </p>
        </div>

        <div
          css={css`
            max-width: 15rem;
            width: 100%;
          `}
        >
          <Signature
            css={css`
              fill: white;
              height: 100%;
              width: 100%;
              transform: rotate(-2deg);
            `}
          />
        </div>
      </div>
    </section>
  )
}

export default MoreToCome
